import Layout from '../components/Layout';
import { Content } from '../components/home/Content';
import { Gallery } from '../components/home/Gallery';

const Home = () => {
    return <Layout>
        {/* page content */}
        <div className='home-container'>
            <Gallery></Gallery>
            <Content></Content>
        </div>

        {/* page content end*/}
    </Layout>
}
export default Home;